.root {
  height: 100%;
  width: 100%;
}

.container {
  padding: 2rem 3rem;
  overflow-x: auto;
  min-height: calc(100vh - 7.5rem);
}

.mobileTopBar {
  background-color: transparent;
  margin-bottom: 1rem;
}

.barHeader {
  font-weight: 600;
}

.mobileBarHeader {
  font-size: 1.5rem;
  font-weight: 700;
}

.componentHeader {
  font-weight: 600;
}

.filterBar {
  display: flex;
  margin-top: 1rem;
}

.mobileFilterBar {
  display: none;
}

.errorText {
  display: block;
  margin-top: 1rem;
  color: var(--pink-base);
}

.equipmentFilter {
  margin-right: 1rem;
}

.equipmentFilterDropdown {
  max-width: 20rem;
}

.mobileFilterDropdown {
  width: calc(100vw - 2rem);
}

.marginRight {
  margin-right: 1rem;
}

.marginTop {
  margin-top: 1rem;
}

.mobileHeader {
  color: var(--ambient-basePlus50);
}

.mobileEquipmentTitle {
  display: inline-block;
  margin-bottom: 0.5rem;
  word-break: break-word;
}

.extraSmallEquipmentFont {
  font-size: 0.65rem;
}

.moreContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.betaText {
  color: var(--pink-base);
}

@media (max-width: 768px), (max-device-width: 768px) {
  .container {
    padding: 0 1rem;
  }

  .tabs,
  .tableCountContainer,
  .filterBar,
  .errorText {
    display: none;
  }

  .mobileFilterBar {
    display: flex;
    justify-content: flex-end;
  }

  .mobileFilterDropdown > div div {
    width: 100%;
  }
}

.filterLevelOne {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.leftContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
}

.headerHeading {
  color: #333e48;
  font-weight: 700;
  display: flex;
  justify-content: flex-end;
  font-size: 1.25rem;
  padding-right: 0.5rem;
}

.rightContent {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.searchBoxContainer {
  padding-left: 1.5rem;
  max-height: 2.5rem;
}

.filterLevelThree {
  position: relative;
  padding-top: 10px;
  border-top: 1px solid var(--ambient-basePlus75);
}

.subHeader {
  display: none;
}

.subHeader {
  padding: -1rem 3rem 0 3rem;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  position: absolute;

  /* 
  padding: 1rem 3rem 0 3rem;
  display: flex;
  justify-content: space-between;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000; */
}

.graphFilterContainer {
  width: 100%;
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
}

.performaceFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--ambient-basePlus);
  padding-bottom: 20px;
}

.performaceFlex .performaceMargin {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.downloadButton {
  min-width: 3.25rem;
  padding: 0.5rem;
}

.loaderOverlay {
  position: relative;
  width: 100%;
  height: 100px;
  z-index: 1;
}

.loaderContainer {
  width: 100%;
  position: absolute;
  top: -400%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progressBar {
  min-height: 0.4rem;
  height: 0.4rem;
  justify-content: flex-start;
  margin-left: 10%;
}

.tooltipContent {
  background-color: black;
  color: white;
  border: 1px solid #ccc;
  padding: 0.5rem;
  width: 6rem;
  height: 4rem;
  pointer-events: none;
  transform: translate(-50%, -100%);
}

.percentage {
  color: var(--turquoise-base);
  font-size: medium;
  font-weight: 400;
}

.date {
  color: var(--ambient-basePlus75);
}

.highlightValue {
  color: #ff514b;
}

.tooltipStyle {
  display: inline-block;
}

.titleText {
  max-width: 30rem;
  height: 12rem;
  font-weight: 400;
  font-size: small;
}
